/*
1. SLOTS BATTLES
├── 1.1. SLOTS BATTLES COMMON ELEMENTS
│   ├── 1.1.1  BTNS
│   ├── 1.1.2  BATTLE CARD + BATTLE INFO CARD
│   ├── 1.1.3  BATTLE LOBBY
│   ├── 1.1.4  BACK BTN
│   ├── 1.1.5  BATTLES TYPES MENU
│   ├── 1.1.6  SLOT BATTLES DROPDOWN
│   ├── 1.1.7  FILTERS + SEARCH
│   ├── 1.1.8  SLOTS BATTLES TOP MENU
│   ├── 1.1.9  GAME ITEM
│   ├── 1.1.10 CHECKBOX
│   ├── 1.1.11 PRIVATE PASS
│   ├── 1.1.12 BRANDLOGO + LOCK
│   ├── 1.1.13 SHARE BTN TOOLTIP
│   ├── 1.1.14 BANNER
│   └── 1.1.15 TUTORIAL
└── 1.2. PAGES
    ├── 1.2.1 MAIN
    └── 1.2.2 CREATE/BATTLE-INFO
*/

/*
|--------------------------------------------------------------------------
| == 1.1. SLOTS BATTLES COMMON ELEMENTS
|--------------------------------------------------------------------------
*/
body {
    --slots-battles--desktop-height: calc(
            100vh - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop} -
            #{$size-height--footer_desktop} - 14rem - 4.4rem
    );
}

.slots-battles--desktop .slots-battles-wrp {
    min-height: var(--slots-battles--desktop-height);
    @include flex-config($flex-direction: column);
}

/*
|--------------------------------------------------------------------------
| == 1.1.1 BTNS
|--------------------------------------------------------------------------
*/

.slots-battles__btn--accent,
.slots-battles__btn--main,
.slots-battles__btn--outlined {
    @extend %G-slots-battles__btn--bounced;

    background: none;
    font-size: 1.5rem;
}

.slots-battles__btn--accent {
    color: #000;

    &::before {
        background: #E1C18F;
    }
}

.slots-battles__btn--main {
    color: #fff;

    &::before {
        background: #2F2631;
    }
}

.slots-battles__btn--outlined {
    color: #E1C18F;

    &::before {
        background: transparent;
        box-shadow: inset 0 0 0.1rem 0.1rem rgba(225, 193, 143, 0.35);
    }
}

.body--with-cursor {
    .slots-battles__btn--accent,
    .slots-battles__btn--main,
    .slots-battles__btn--outlined {
        @extend %G-slots-battles__btn--bounced-effect;
    }

    .slots-battles__btn--accent:not([disabled]):hover {
        background: transparent;
        color: #ffffffd9;

        &::before {
            background: #E1C18F60;
            box-shadow: inset 0 0 0.1rem 0.1rem #E1C18F;
        }
    }

    .slots-battles__btn--main:not([disabled]):hover {
        background: transparent;

        &::before {
            background: #2F26315A;
            box-shadow: inset 0 0 0.1rem 0.1rem #2F2631;
        }
    }

    .slots-battles__btn--outlined:not([disabled]):hover {
        background: transparent;
        color: #E1C18F;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.2 BATTLE CARD + BATTLE INFO CARD
|--------------------------------------------------------------------------
*/

.battle-info-card {
    @extend %G-battle-card;
    @extend %G-battle-info-card;

    background: #291A2D;
    color: #E1E1E1;

    .battle-info-card__del-btn {
        background: #291A2D;
        color: #E1E1E1;
    }

    .slots-battles__private-pass-form {
        background: #291A2Daa;
    }

    .slots-battles__private-pass-form-inner {
        background: #392F3B;
    }
}

.battle-info-card--desktop {
    @extend %G-battle-card--desktop;
    @extend %G-battle-info-card--desktop;
}

.battle-info-card--tablet {
    @extend %G-battle-card--tablet;
    @extend %G-battle-info-card--tablet;
}

.battle-info-card--mobile {
    @extend %G-battle-card--mobile;
    @extend %G-battle-info-card--mobile;
}

/*
|--------------------------------------------------------------------------
| == 1.1.3 BATTLE LOBBY
|--------------------------------------------------------------------------
*/

.battle-lobby {
    @extend %G-battle-card;
    @extend %G-battle-lobby;

    background: #291A2D;
    color: #fff;

    .battle-lobby__battle-share-btn {
        background: #392F3B;
    }

    .battle-lobby__battle-invite-btn {
        background: #e1c18f;
        color: #000;
    }

    .battle-lobby__detail-content {
        color: #E1E1E1;
    }

    .battle-lobby__detail-winner-row {
        position: relative;
        color: #20a100;
        transform: scale(1);
        animation: battleWinner;
        animation-duration: 2.5s;
    }

    @keyframes battleWinner {
        20% {
            transform: scale(0.95);
            text-shadow: 0 0 0 rgba(225, 193, 143, 0);
        }
        40% {
            transform: scale(1);
            text-shadow: 0 0 20px rgba(225, 193, 143, 0.7);
            color: #E1C18F;
        }
        60% {
            transform: scale(0.95);
            text-shadow: 0 0 0 rgba(225, 193, 143, 0);
        }
        80% {
            transform: scale(1);
            text-shadow: 0 0 20px rgba(225, 193, 143, 0.7);
            color: #E1C18F;
        }
        100% {
            transform: scale(1);
            text-shadow: 0 0 0 rgba(225, 193, 143, 0);
        }
    }
}

.battle-lobby--desktop {
    @extend %G-battle-card--desktop;
}

.battle-lobby--tablet {
    @extend %G-battle-card--tablet;
    @extend %G-battle-lobby--tablet;
}

.battle-lobby--mobile {
    @extend %G-battle-card--mobile;
}

.body--with-cursor {
    .battle-lobby__battle-share-btn:hover {
        color: #E1C18F;
    }
    .battle-lobby__battle-invite-btn:hover {
        opacity: .8;
        color: #000;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.4 BACK BTN
|--------------------------------------------------------------------------
*/

.slots-battles-back-btn {
    @extend %G-slots-battles-back-btn;

    color: #BEBEBE;
}

.body--with-cursor {
    .slots-battles-back-btn:hover {
        color: #AF8245;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.5 BATTLES TYPES MENU
|--------------------------------------------------------------------------
*/

.slots-battles-types-menu {
    @extend %G-slots-battles-types-menu;

    .slots-battles-types-menu__item {
        border-right: 0.1rem solid #BEBEBE;
    }

    .slots-battles-types-menu__game-type--active {
        color: #AF8245;
    }
}

.body--with-cursor {
    .slots-battles-types-menu__item:hover,
    .slots-battles-types-menu__questions:hover {
        color: #AF8245;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.6 SLOT BATTLES DROPDOWN
|--------------------------------------------------------------------------
*/

.slots-battles-dropdown {
    @extend %slots-battles-dropdown;

    .slots-battles-dropdown__wrapper {
        background: #392F3B;

        .ll-dropdown-menu-item,
        .ll-dropdown-menu-item--active {
            background: #2A1130;
            color: #ffffff;

            &:not(:last-child) {
                border-bottom: 0.1rem solid #392F3B;
            }
        }

        .ll-dropdown-menu-item--active {
            color: #E1C18F;
        }

        &.ll-dropdown-wrapper--chosen,
        &.ll-dropdown-wrapper--disabled {
            color: #BEBEBE;
        }
    }

    .slots-battles-dropdown__scrollbox {
        &::-webkit-scrollbar-thumb {
            background: linear-gradient(0deg, #AF8245, #AF8245), #AF8245 !important;
        }

        &::-webkit-scrollbar-track {
            background-color: #392F3B !important;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.7 FILTERS + SEARCH
|--------------------------------------------------------------------------
*/

// Filter

.slots-battles-filter {
    @extend %G-slots-battles-filter;
}

// Search

.slots-battles-search {
    @extend %G-slots-battles-search;

    box-shadow: inset 0 0 .1rem .1rem #392F3B;

    .slots-battles__search-input {
        .ll-input__textbox {
            input {
                color: #C7C7C7;
            }
        }

        .dx-texteditor.dx-editor-outlined {
            box-shadow: none !important;
            color: #C7C7C7 !important;
            background-color: transparent !important;
        }
    }
}

.slots-battles-search--desktop {
    @extend %G-slots-battles-search--desktop;
}

.slots-battles-search-dummy {
    @extend %G-slots-battles-search-dummy;
}

.slots-battles-search--mobile {
    @extend %G-slots-battles-search--mobile;

    background: #392F3B;

    .slots-battles-search__input {
        .dx-texteditor.dx-editor-outlined {
            box-shadow: none !important;
            background-color: transparent !important;
        }

        .ll-input__textbox {
            border: none;
            box-shadow: none;

            input,
            .dx-placeholder::before {
                color: #BEBEBE;
            }

            input {
                color: #FFFFFF;
            }
        }
    }
}

// Filter + Search Wrapper

.slots-battles__filters-search {
    @extend %G-slots-battles__filters-search;
}

.slots-battles__filters-search--desktop {
    @extend %G-slots-battles__filters-search--desktop;
}

.slots-battles__filters-search--tablet,
.slots-battles__filters-search--mobile {
    @extend %G-slots-battles__filters-search--tablet--mobile;
}

.slots-battles__filters-search--tablet {
    @extend %G-slots-battles__filters-search--tablet;
}

.slots-battles__filters-search--mobile {
    @extend %G-slots-battles__filters-search--mobile;

    .slots-battles__filters-wrp--menu {
        background: #291A2D;
    }
}

.body--with-cursor {
    .slots-battles-dropdown__wrapper:not(.ll-dropdown-wrapper--disabled) {
        &:hover {
            color: #E1C18F;
        }
    }

    .ll-dropdown-menu-item:hover {
        color: #E1C18F;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.8 SLOTS BATTLES TOP MENU
|--------------------------------------------------------------------------
*/

.slots-battles-top-menu {
    @extend %G-slots-battles-top-menu;

    background: #291A2D;
    color: #BEBEBE;

    .slots-battles__text {
        color: #e1c18f;
    }
}

.slots-battles-top-menu--desktop {
    @extend %G-slots-battles-top-menu--desktop;
}

.slots-battles-top-menu--tablet,
.slots-battles-top-menu--mobile {
    @extend %G-slots-battles-top-menu--tablet-mobile;

    .slots-battles-types-menu__questions {
        box-shadow: inset 0 0 0.1rem 0.1rem #BEBEBE;
    }
}

.slots-battles-top-menu--tablet {
    @extend %G-slots-battles-top-menu--tablet;
}

.slots-battles-top-menu--mobile {
    @extend %G-slots-battles-top-menu--mobile;

    .filter-btn-search__icon-wrp::before {
        background: #291A2D;
    }

    .slots-battles-top-menu__filter-btn-search {
        border-bottom: 0.1rem solid #2A1130;

        .filter-btn-search__item .filter-icon {
            background: #392F3B;
        }

        .filter-btn-search__item-filter {
            background: #392F3B;
        }

        .filter-btn-search__item-button {
            background: #E1C18F;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.9 GAME ITEM
|--------------------------------------------------------------------------
*/

.slots-battles-game-item {
    @extend %G-slots-battles-game-item;

    background: #291A2D;
    color: #DADAD9;

    .slots-battles-game-item__info-item {
        &.slots-battles-game-item__info-item--prime {
            color: #FFFFFF;
        }

        &.slots-battles-game-item__info-item--accent {
            color: #E1C18F;
        }
    }

    .slots-battles-game-item__highlighted-part {
        color: #E1C18F;
        font-weight: 700;
    }

    .slots-battles__private-pass-form {
        background: #291A2Daa;
    }

    .slots-battles__private-pass-form-inner {
        background: #392F3B;
    }
}

.slots-battles-game-item--desktop {
    @extend %G-slots-battles-game-item--desktop;
}

.slots-battles-game-item--tablet,
.slots-battles-game-item--mobile {
    @extend %G-slots-battles-game-item--tablet--mobile;
}

.body--with-cursor {
    .slots-battles-game-item {
        &:hover {
            background: #2A122F;

            .slots-battles__private-pass-form {
                background: #2A122Faa;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.10 CHECKBOX
|--------------------------------------------------------------------------
*/

.slots-battles-checkbox {
    @extend %G-slots-battles-checkbox;

    .dx-checkbox-icon {
        border: none;
        outline: 0.1rem solid #E1C18F;

        &::before {
            background: #E1C18F;
            color: #291A2D;
            font-weight: bold;
            border-radius: 0.3rem;
        }
    }

    .dx-checkbox-text {
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.11 PRIVATE PASS
|--------------------------------------------------------------------------
*/

.slots-battles__private-pass {
    @extend %G-slots-battles__private-pass;

    .ll-input__textbox {
        background-color: #392F3B !important;
    }

    &.ll-input--focused,
    &.ll-input--labeled-up {
        .ll-input__label:not(.ll-input__label--top) {
            background: linear-gradient(180deg, transparent 55%, #392F3B 45%);
        }
    }

    .dx-texteditor.dx-editor-outlined,
    .dx-texteditor.dx-editor-outlined.dx-state-hover {
        -webkit-box-shadow: inset 0 0 0 1px #BEBEBE !important;
        box-shadow: inset 0 0 0 1px #BEBEBE !important;
    }

    .dx-show-clear-button .dx-icon-clear {
        border: none;
        background-color: transparent;
        color: #BEBEBE;
    }

    .ll-input__label--inside {
        color: #BEBEBE !important
    }

    input {
        color: #fff !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.12 BRANDLOGO + LOCK
|--------------------------------------------------------------------------
*/

.slots-battles-lock {
    @extend %G-slots-battles-lock;

    color: #FFF;
}

/*
|--------------------------------------------------------------------------
| == 1.1.13 SHARE BTN TOOLTIP
|--------------------------------------------------------------------------
*/

.slot-battles-copy-tooltip {
    @extend %G-slot-battles-copy-tooltip;

    background: #291A2D80;
    color: #BEBEBE;
    outline: 0.1rem solid #E1C18F80;
}

/*
|--------------------------------------------------------------------------
| == 1.1.14 BANNER
|--------------------------------------------------------------------------
*/

.slots-battles-banner {
    @extend %G-slots-battles-banner;

    .slots-battles-banner__item--default {
        background-color: #130c14;
        background-image: url('../../../../static/themes/theme/images/banners/slot-battles.jpg');
        color: #fff;
    }
}

.slots-battles-banner--mobile {
    .slots-battles-banner__default-banner {
        background-image: url('../../../../static/themes/theme/images/banners/slot-battles-mobile.jpg');
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1.15 TUTORIAL
|--------------------------------------------------------------------------
*/

.ll-modal--battles-tutorial {
    @extend %G-ll-modal--battles-tutorial;

    .ll-modal__header {
        background: #291A2D;
        color: #fff;
    }

    .ll-modal__custom-content {
        background: #392F3B;
    }
}

.battle-tutorial-modal {
    @extend %G-battle-tutorial-modal;

    .battle-tutorial-modal__freespin-instruction-tip,
    .battle-tutorial-modal__freespin-instruction-btn {
        border: 2px solid #E1C18F;
        background: #291A2D;
    }

    .battle-tutorial-modal__freespin-instruction-tip span {
        color: #E1C18F;
    }

    .battle-tutorial-modal__parent-indicator {
        background: #291A2D;
        border-right: 2px solid #E1C18F;
        border-bottom: 2px solid #E1C18F;
    }

    .battle-tutorial-modal__freespin-instruction-btn {
        color: #E1C18F;
    }

    .battle-tutorial-modal__bets-count-icon--small {
        border: 1px solid #E1C18F;
        background: #291A2D;

        &::before {
            color: #E1C18F;
        }
    }

    .battle-tutorial-modal__bets-count-icon--big {
        border: 1px solid #BEBEBE;

        &::before {
            color: #BEBEBE;
        }
    }
}

.battle-tutorial-modal--desktop {
    @extend %G-battle-tutorial-modal--desktop;
}

.battle-tutorial-modal--tablet {
    @extend %G-battle-tutorial-modal--tablet;
}

.battle-tutorial-modal--mobile {
    @extend %G-battle-tutorial-modal--mobile;
}

.battle-tutorial-popup {
    @extend %G-battle-tutorial-popup;

    background: #291A2D;
    color: #fff;

    .battle-tutorial-popup__parent-indicator {
        background: #291A2D;
    }
}

.battle-tutorial-popup--desktop {
    @extend %G-battle-tutorial-popup--desktop;
}

.battle-tutorial-popup--tablet {
    @extend %G-battle-tutorial-popup--tablet;
}

.battle-tutorial-popup--mobile {
    @extend %G-battle-tutorial-popup--mobile;
}

.battle-tutorial-game-instruction-img {
    background: url("../../../../static/themes/theme/images/slots-and-casino/slot-scheme.svg") no-repeat center;
}

.battle-questions {
    @extend %G-battle-questions;
}

.battle-questions--tablet {
    @extend %G-battle-questions--tablet;
}

.battle-questions--mobile {
    @extend %G-battle-questions--mobile;
}

.body--with-cursor {
    .ll-modal--battles-tutorial {
        .ll-modal__scrollbox--desktop {
            *::-webkit-scrollbar-thumb {
                background: linear-gradient(0deg, #AF8245, #AF8245), #AF8245 !important;
            }

            *::-webkit-scrollbar-track {
                background-color: #392F3B !important;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.2. PAGES
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 1.2.1 MAIN
|--------------------------------------------------------------------------
*/

.slots-battles {
    @extend %G-slots-battles;

    .games-no-data {
        color: #fff;
    }

    .step-icon {
        border: 0.1rem solid #fff;
    }

    .battle-info-card__timer {
        color: #E1C18F;
    }
}

.slots-battles--desktop {
    @extend %G-slots-battles--desktop;

    .slots-battles__content--new {
        min-height: var(--slots-battles--desktop-height);
        max-height: var(--slots-battles--desktop-height);
        background-color: #2c2c2c;
        background-image: url('../../../../static/themes/theme/images/slots-and-casino/slot-battles-lobby.jpg');
    }

    .games-no-data {
        min-height: var(--slots-battles--desktop-height);
        max-height: var(--slots-battles--desktop-height);
    }
}

.slots-battles--tablet,
.slots-battles--mobile {
    @extend %G-slots-battles--tablet-mobile;

    background: #231D24;
}

.slots-battles--tablet {
    @extend %G-slots-battles--tablet;
}

.slots-battles--mobile {
    @extend %G-slots-battles--mobile;
}

.body--with-cursor {
    .battle-info-card__del-btn:hover {
        background: transparent;
        color: #FFFFFF;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.2.2 CREATE/BATTLE-INFO
|--------------------------------------------------------------------------
*/

.create-battle,
.battle-info {
    @extend %G-battle-details;
}

.create-battle {
    @extend %G-create-battle;

    color: #fff;

    .create-battle-menu {
        background: #291A2D;

        .create-battle-menu__form-item {
            background: #392F3B;
            color: #ffffff;
        }

        .ll-input__label {
            background: linear-gradient(180deg, transparent 55%, #291A2D 45%) !important;
        }

        .dx-texteditor {
            background: transparent !important;
        }

        .dx-texteditor-input {
            color: #fff !important;
        }

        .create-battle-menu__form-item--active {
            color: #E1C18F;
        }

        .create-battle-menu__form-item--chosen {
            color: #BEBEBE;
        }
    }

    .create-battle-games-list {
        background: #291A2D;
    }

    .create-battle-games-list__close-btn {
        background: transparent;
        border: none;
        color: #BEBEBE;
    }
}

.create-battle--desktop {
    @extend %G-create-battle--desktop;

    .create-battle-games-list {
        height: calc(var(--slots-battles--desktop-height) - 4rem);
    }

    .create-battle-games-list__games-item {
        min-width: $size-width--slot-battles-game_desktop + px;
        max-width: $size-width--slot-battles-game_desktop + px;
        margin: $size-margin--slot-battles-game_desktop + px;
    }
}

.create-battle--tablet {
    @extend %G-create-battle--tablet;

    .create-battle-games-list {
        height: calc(var(--slots-battles--desktop-height) - 4rem);
    }

    .create-battle-games-list__games-item {
        min-width: $size-width--slot-battles-game_desktop + px;
        max-width: $size-width--slot-battles-game_desktop + px;
        margin: $size-margin--slot-battles-game_desktop + px;
    }
}

.create-battle--mobile {
    @extend %G-create-battle--mobile;

    .create-battle-games-list__games-item {
        @include size($size-width--slot-battles-game_mobile + px, calc($size-width--slot-battles-game_mobile + ($size-width--slot-battles-game_mobile * 0.3)) + px);
        margin: $size-margin--slot-battles-game_mobile + px;

        @include mq('(max-width: 374px)') {
            @include size($size-width--slot-battles-game_mobile-iphone5 + px, calc($size-width--slot-battles-game_mobile-iphone5 + ($size-width--slot-battles-game_mobile-iphone5 * 0.3)) + px);
            margin: $size-margin--slot-battles-game_mobile-iphone5 + px;
        }
    }
}

.body--with-cursor {
    .create-battle-menu__form-item:hover {
        color: #E1C18F;
    }

    .create-battle-games-list__close-btn:hover {
        color: #fff;
    }
}
