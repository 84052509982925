/*
3.PAGES
└── 3.2. SIGNUP PAGE
   ├── 3.2.1 SIGNUP DESKTOP
   ├── 3.2.2 SIGNUP TABLET
   └── 3.2.3 SIGNUP MOBILE
*/

/*
|--------------------------------------------------------------------------
| == 3.2. SIGNUP PAGE
|--------------------------------------------------------------------------
*/

.ll-input__pwdStrength {
    @extend %G-ll-input__pwdStrength;

    &.weak {
        color: #f44336;
    }

    &.perfect {
        color: #779866;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.1. SIGNUP DESKTOP
|--------------------------------------------------------------------------
*/

.signup--desktop,
.signup--tablet {
    @extend %G-signup--desktop;
}

.signup--desktop,
.signup--tablet,
.signup--mobile {
    .ll-input label.ll-input__label--inside,
    .signup__currencies .signup__field-label {
        color: #fff;
    }

    .dx-texteditor.dx-editor-outlined.dx-invalid .dx-texteditor-container {
        box-shadow: inset 0 0 0 1px #f65246;
    }
    .dx-texteditor.dx-editor-outlined.dx-state-focused {
        -webkit-box-shadow: inset 0 0 0 2px #E1C18f55 !important;
        box-shadow: 0 0 8px 1px #E1C18F55 !important;
    }

    .dx-texteditor.dx-editor-outlined.dx-state-focused {
        -webkit-box-shadow: inset 0 0 0 2px #E1C18F55 !important;
        box-shadow: 0 0 8px 1px #E1C18F55 !important;
    }

    .signup__form-header {
        background: #291A2D;
        color: #E1C18F;
    }

    .signup__container {
        background-color: #313031;
        border-radius: 0.5rem;
    }

    .signup__sumbit-btn {
        background: #E1C18F;
    }

    .signup__gender {
        .dx-collection {
            color: #fff;
        }

        .dx-radiobutton {
            background: #2E2C2E;
            box-shadow: inset 0 0 0 1px #BEBEBE;
        }

        .dx-radiobutton-icon:before {
            border: 2px solid #DBBD6C;
            background: transparent;
        }

        .dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
            background: #DBBD6C;
        }
    }

    .signup__country-picker,
    .ll-date-picker--desktop input,
    .ll-date-picker--mobile input,
    .signup__currencies {
        color: #fff;
        background: #2E2C2E;
        box-shadow: inset 0 0 0 1px #BEBEBE;
    }

    .signup__phone-flag-container {
        color: #fff;
        border-right: 1px solid #5f5f5f;
    }

    .signup__footer-link {
        color: #E1C18F;
        cursor: pointer;
    }

    .signup__footer-text {
        color: #fff;

        .ll-btn.ll-btn--accent {
            background: #DBBD6C;
        }
    }

    .signup__footer-eighteen {
        color: #fff;
    }

    .signup__footer-eighteen--check {
        .dx-checkbox-icon {
            background: transparent;
            border: 2px solid #DBBD6C;
            color: #DBBD6C;
            font-weight: bold;
        }
    }

    .form__captcha-text {
        color: #fff;
    }

    .form__captcha-link {
        color: #DBBD6C;
    }

    .signup__login {
        color: #fff;
    }

    .signup__login-btn {
        background: transparent;
        color: #DBBD6C;
    }

    .signup__errors {
        .dx-item-content {
            border: none;
            color: #f65246;

            &:before {
                color: #f65246;
            }
        }
    }

    .signup__footer-eighteen--error {
        color: #f65246;
    }
}

.body--with-cursor {
    .signup--desktop,
    .signup--tablet {
        .form__captcha-link:hover,
        .signup__footer-link:hover {
            text-decoration: underline;
        }

        .signup__sumbit-btn:hover {
            opacity: 1;
            background: #DBBD6C;
        }

        .dx-item:hover {
            font-size: 1.4rem;

            .dx-item-content,
            .dx-item-content:before {
                color: #DBBD6C;
            }
        }

        .dx-texteditor-container {
            &:focus-within {
                box-shadow: 0 0 8px 1px #E1C18F55;
            }
        }
        .dx-texteditor.dx-editor-outlined.dx-state-hover{
            box-shadow: inset 0 0 0 1px #bebebe
        }

    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.2 SIGNUP TABLET
|--------------------------------------------------------------------------
*/

.signup--desktop {
    .signup__form-header {
        color: #fff;
    }

    .signup__container {
        background: #4D4D4D;
    }
}

.signup--tablet {
    @extend %G-signup--tablet;
}

.signup--tablet,
.signup--mobile {
    .signup__container {
        background: transparent;
    }

    .signup__footer-eighteen,
    .form__captcha-text {
        background: #2E2C2E;
        box-shadow: inset 0 0 0 1px #BEBEBE;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.2.3 SIGNUP MOBILE
|--------------------------------------------------------------------------
*/

.signup--mobile {
    @extend %G-signup--mobile;

    color: #fff;

    .signup__header--mobile {
        background-color: #291A2D;
        color: #E1C18F;
    }
}
