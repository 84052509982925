/*
3.PAGES
└── 3.5. PROMO PAGE
    ├── 3.5.1 PROMO PAGE CARDS LIST
    └── 3.5.2 PROMO PAGE MODAL
*/

/*
|--------------------------------------------------------------------------
| == 3.5 PROMO PAGE
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| == 3.5.1 PROMO PAGE CARDS LIST
|--------------------------------------------------------------------------
*/

.promo-content {
    @extend %G-promo-content;
    min-height: calc(
            100vh - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop}
    );

    .promo-content__title {
        color: #fff;
    }

    .promo-content__cards--big {
        .promo-content__inner-title,
        .promo-content__inner-title--empty {
            @include mq('desktop') {
                font-size: 1.8rem;
            }
        }
    }

    .promo-content__item {
        color: #fff;
        box-shadow: 0.3rem 0.2rem 0.5rem rgba(0, 0, 0, 0.3);

        &:hover {
            box-shadow: 1.2rem 0.8rem 1.5rem rgba(0, 0, 0, 0.3);
        }
    }

    //.promo-content__inner,
    //.promo-content__inner--empty {
    //    &::before {
    //        background: linear-gradient(0deg, #72727247, #1b1b1b);
    //    }
    //}

    .promo-content__inner-icon-wrp {
        background-color: #000;
        font-size: 1.8rem;
    }

    .promo-content__inner-descr {
        font-weight: 300;
    }

    .promo-content__btn-wrp {
        border: 0.1rem solid #E1C18F;
    }

    .promo-content__btn {
        font-size: 1.4rem;
        background: #2A122F;
        color: #fff;

        &.ll-btn--square-outlined--desktop,
        &.ll-btn--square-outlined--tablet,
        &.ll-btn--square-outlined--mobile {
            background: #E1C18F;
            color: #000;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.5.2 PROMO PAGE MODAL
|--------------------------------------------------------------------------
*/
.promo-modal {
    @extend %G-promo-modal;

    .promo-modal__btn--secondary {
        background: #E1C18F;
        color: #000;
    }

    .promo-modal__btn--primary {
        background: #2A122F;
        color: #fff;
    }
}

.body--with-cursor {
    .promo-content {
        .promo-content__btn:hover {
            background: #392F3B;
        }

        .ll-btn--square-outlined--desktop:hover,
        .ll-btn--square-outlined--tablet:hover,
        .ll-btn--square-outlined--mobile:hover {
            background: #AF8245;
        }
    }

    .promo-modal {
        .promo-modal__btn--secondary:hover {
            box-shadow: inset 0 0 1px 1px #AF8245;
            background: #AF8245;
        }

        .promo-modal__btn--primary:hover {
            box-shadow: inset 0 0 1px 1px #392F3B;
            background: #392F3B;
        }
    }
}
