.pre-match-details__market-group__custom-bets {
    background: #2F2631;
}

.ll-custom-bets {
    .ll-custom-bets__info {
        background-color: #4B4B4B50;
    }

    .ll-custom-bets__about-image {
        background-image: url("../../../../static/themes/theme/images/custom-bets/custom-bets.png");
    }

    .ll-custom-bets__title {
        color: #fff;
        background: #4B4B4B;
    }

    .ll-custom-bets__about-terms-link {
        color: #E1C18F;
    }

    .ll-custom-bets__open-close-info {
        color: white;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #2F2631;
        color: #fff;
    }

    .ll-custom-bets-pre__market-group {
        color: #BEBEBE;
    }

    .ll-custom-bets-pre__market-group--active {
        color: #D6AA50;
    }

    .match-details__market {
        background-color: #4B4B4B;
    }

    .match-details__bet--custom-unavailable {
        opacity: .5;
        cursor: not-allowed;
    }
}

.ll-scrollbox--tablet .ll-custom-bets,
.ll-scrollbox--desktop .ll-custom-bets {
    .fancy-bets-slider-wrp {
        background-color: #5D5D5D;
    }

    .fancy-bets-slider__rail {
        color: #392F3B;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #2F2631;
        color: #fff;
    }

    .match-details__market-header {
        background-color: #BEBEBE;
        color: #000;
    }

    .match-details__market .match-details__bets {
        border-color: #BEBEBE;
    }

    .match-details__bet {
        color: #000;
        background-color: #fff;

        &.match-details__bet--active {
            background-color: #E1C18F;
        }

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -1px 0 0 0 #BEBEBE;
        }

        &.bet--column3 {
            box-shadow: inset -1px 0 0 0 #BEBEBE;
        }
    }
}

.ll-scrollbox--mobile .ll-custom-bets {
    @extend %G-custom-bets--mobile;

    .ll-custom-bets__about-text {
        padding-bottom: 3rem;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #291a2d;
    }

    .match-details__market-header {
        background-color: #321E36;
        color: #fff;
    }

    .match-details__bets {
        background: #464147;
        border-bottom: 2px solid #321E36;
    }

    .match-details__bet {
        border: none;
        border-right: 2px solid #321E36;
        background-color: #392F3B;
        color: #fff;

        &.match-details__bet--active {
            background-color: #AF8245;
            color: #000;
        }
    }
}

.body--with-cursor {
    .bet-builder-modal__skip-btn.ll-btn--outlined:hover {
        color: #000;
    }

    .ll-custom-bets {
        .ll-custom-bets__about-terms-link:hover {
            color: #FFFFFF;
        }

        .ll-custom-bets__open-close-info:hover {
            color: #E1C18F;
        }

        .ll-custom-bets-pre__market-group:hover {
            color: #D6AA50;
            background: transparent !important;
        }

        .ll-custom-bets__bet-button:hover {
            background-color: #E1C18F;
            color: #000;
        }

        .match-details__bet:not(.match-details__bet--disabled):hover {
            background: #E1C18F;
            border-color: #E1C18F;
            color: #000;
        }
    }

    .ll-custom-bets__about-info-button:hover {
        color: #E1C18F;
    }
}

.bet-builder-modal__info-header {
    color: #E1C18F;
}

.ll-custom-bets__about-info-container {

    .ll-custom-bets__about-info-button {
        color: #FFFFFF;
    }

    .ll-custom-bets__about-info-content-container {
        color: #ffffff;
        background-color: #353236;
        border-color: #2A1130;
    }

    .ll-custom-bets__about-info-close {
        color: #BEBEBE;
    }
}
