.slots,
.casino {
    @extend %G-games;

    .games__header-menu-container {
        background-color: #2a122f;
    }

    .slots-casino-menu-wrapper {
        color: #fff;

        .games-menu__btn {
            background-color: #af8245;
            color: #000;
        }

        .menu-item__img {
            background-color: transparent;
        }
    }

    .games__item-status-icon--favorite {
        @extend %G-games-icon--favorite;
        color: #fff;
    }

    .games__item-status-icon--promoted {
        @extend %G-games-icon--promoted;
        background-color: #E1C18F;
        color: #000;
    }

    .games-icon--exclusive-wrp::before,
    .games-icon--exclusive-wrp::after {
        background: #E1C18F;
    }

    .games-icon--exclusive-label {
        &::before {
            color: #000;
        }
    }

    .games__item--without-image {
        background-image: url('../../../../static/themes/theme/images/slots-and-casino/slot-casino-without-img-big.png');
        color: #fff;
    }

    .game__provider-without-img {
        background: #2A122F;
        color: #fff;
        border: 1px solid #c1c1c1;
    }

    .game__game-name {
        background-color: #2A122F;
        color: #fff;
    }
}

.body--with-cursor {
    .games-menu__btn:hover {
        background: #e1c18f;
    }
}

.games__list--empty {
    color: #fff;
}

.slots--desktop,
.casino--desktop {
    @extend %G-games__desktop;

    .slots-casino--loader-container__desktop {
        height: calc(
                100vh - #{$size-height--header-upper-part_desktop + px} - #{$size-height--footer_desktop} -
                #{$size-height--content-footer}
        );
    }

    .slots-casino-menu__item:hover,
    .slots-casino-menu__item--active,
    .slots-casino-menu__item--active:hover {
        color: #af8245;
    }

    .games__search {
        border: 1px solid #ffffff50;

        .ll-input__textbox {
            input {
                color: #fff;
            }
        }
    }

    .games__list {
        min-height: calc(#{$size-max-height--page-container_desktop} - 300px - 90px);
    }

    .games__item {
        height: $size-height--new-thumbnail-game_desktop + px;
        width: $size-width--new-thumbnail-game_desktop + px;
        margin: $size-margin--new-thumbnail-game_desktop + px;
    }

    .games-icon--exclusive-wrp {
        @extend %G-games-icon--exclusive-wrp__desktop;
        .games-icon--exclusive-label {
            background: linear-gradient(-45deg,
                    transparent calc(50% - .1rem),
                    #E1C18F 50%,
                    #E1C18F calc(50% + 2rem),
                    transparent calc(50% + 2rem)
            );
        }
    }

    .game__fun-button {
        background: #2A122F;
        color: #FFFFFF;
    }

    .game__real-button {
        background: #E1C18F;
        color: #000000;
    }

    .game__fun-button:hover {
        background: #2A122FCC;
    }

    .game__real-button:hover {
        background: #E1C18FBF;
    }
}

.slots--desktop {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/desktop-slots-default-banner.jpg");
    }
}

.casino--desktop {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/desktop-casino-default-banner.jpg");
    }
}

.slots--tablet,
.casino--tablet,
.slots--mobile,
.casino--mobile {
    .games-icon--exclusive-wrp {
        @extend %G-games-icon--exclusive-wrp__mobile;
        .games-icon--exclusive-label {
            background: linear-gradient(-45deg,
                    transparent calc(50% - .1rem),
                    #E1C18F 50%,
                    #E1C18F calc(50% + 1.5rem),
                    transparent calc(50% + 1rem)
            );
        }
    }
}

.slots--tablet,
.casino--tablet {
    @extend %G-games__tablet;

    .slots-casino-menu__item--active {
        color: #e1c18f;
    }

    .games__search {
        border: 1px solid #ffffff50;

        .ll-input__textbox {
            input {
                color: #fff;
            }
        }
    }

    .games__list {
        min-height: calc(
                100vh - #{$size-height--header-upper-part_desktop + px} - #{$size-height--footer_desktop} -
                #{$size-height--content-footer}
        );
    }

    .games__item {
        height: $size-height--new-thumbnail-game_tablet + px;
        width: $size-width--new-thumbnail-game_tablet + px;
        margin: $size-margin--new-thumbnail-game_tablet + px;
    }
}

.slots--tablet {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/desktop-slots-default-banner.jpg");
    }
}

.casino--tablet {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/desktop-casino-default-banner.jpg");
    }
}

.slots--mobile,
.casino--mobile {
    @extend %G-games__mobile;

    background-color: #2a122f;

    .games-menu--mobile {
        background-color: #2a122f;

        .games-menu__filter-header-item {
            color: #fff;

            &:not(:last-of-type) {
                border-right: 1px solid #ffffff80;
            }
        }

        .games__search {
            color: #bbb;

            input {
                box-shadow: inset 0 0 0 2px #392f3b;
                color: #BBBBBB;
            }
        }

        .games-menu__btn {
            background-color: #392f3b;
            color: #FFFFFF;
        }

        .games-menu__btn--active {
            color: #e1c18f;
        }

        .games-menu__btn--checked {
            color: #e1c18f;

            &::before {
                background-color: #e1c18f;
            }

            &::after {
                border-color: #392f3b;
            }
        }

        .games-menu__submenu-box {
            background-color: #392f3b;
            color: #FFFFFF;
        }

        .games-menu__submenu-content {
            background-color: #2a122f;
        }

        .games-menu__submenu-header {
            background-color: #392f3b;
        }

        .games-menu__submenu-item.active {
            color: #e1c18f;
        }

        .games-menu__submenu-item-img {
            background-color: #000;
            border: 1px solid hsla(0,0%,100%,.314);
        }
    }

    .games__list--empty {
        color: #fff;
    }

    .games__item {
        height: $size-height--new-thumbnail-game_mobile + px;
        width: $size-width--new-thumbnail-game_mobile + px;
        margin: $size-margin--new-thumbnail-game_mobile + px;
    }

    .games__item--without-image {
        background-image: url('../../../../static/themes/theme/images/slots-and-casino/slot-casino-without-img.png');
    }
}

.slots--mobile {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/mobile-slots-default-banner.jpg");
    }
}

.casino--mobile {
    .games__header-banner {
        background-image: url("../../../../static/themes/theme/images/slots-and-casino/mobile-casino-default-banner.jpg");
    }
}

/* ~~~~~~~~~ JACKPOTS ~~~~~~~~~~ */

.games__list {
    @extend %G-jackpots;

    .jackpot-group__header {
        background-color: #291A2D;

        .jackpot-group__header-label {
            color: #FFFFFF;
        }

        .jackpot-group__header-link {
            color: #fff;
        }
    }

    .jackpot-group__dropdown-wrapper {
        background-color: #392F3B;
        color: #fff;

        .jackpot-group__dropdown-item--brand {
            background-color: #392F3B80;
            color: #fff;
        }

        .ll-dropdown-menu-item {
            background-color: #2A122F;
            box-shadow: inset 0 -1px 0 0 #392F3B;
            color: #BEBEBE;
        }
    }

    .game-list__group-wrapper {
        .jackpot-group__label--grand {
            color: #E1C18F;
        }

        .jackpot-group__label--major {
            color: #AF8245;
        }

        .jackpot-group__label-value {
            color: #000000;
        }

        .jackpot-group__label-value--grand {
            background-color: #E1C18F;

            &:hover {
                background-color: #AF8245;
            }
        }

        .jackpot-group__label-value--major {
            background-color: #AF8245;

            &:hover {
                background-color: #E1C18F;
            }
        }

        .jackpot-group__label--minor,
        .jackpot-group__label--mini {
            color: #FFFFFF;
        }

        .jackpot-group__small-jackpots {
            .jackpot-group__label-value {
                box-shadow: inset 0 0 1px 1px #AE8144;
                color: #fff;

                &:hover {
                    box-shadow: none;
                    background-color: #AF8245;
                    color: #000000;
                }
            }
        }

        .jackpot-group__details--grand,
        .jackpot-group__details--major {
            box-shadow: inset 0 0 1px 1px #E1C18F;
            color: #fff;

            .jackpot-group__details-row-info--first {
                color: #E1C18F;
            }
        }

        .jackpot-group__details--minor,
        .jackpot-group__details--mini {
            box-shadow: inset 0 0 1px 1px #E1C18F;
            color: #fff;

            .jackpot-group__details-row-info--first {
                color: #AC7F43;
            }
        }

        .jackpot-group__details-date {
            color: #BEBEBE;
        }

        .jackpot-group__details-totals {
            color: #BEBEBE;
        }
    }
}

.slots--mobile .games__list,
.casino--mobile .games__list {
    @extend %G-jackpots--mobile;
}

.slots--tablet .games__list,
.casino--tablet .games__list,
.slots--desktop .games__list,
.casino--desktop .games__list {
    @extend %G-jackpots--tablet-desktop;
}

.body--with-cursor {
    .games__list {
        .jackpot-group__dropdown-wrapper:hover {
            color: #E1C18F;
            cursor: pointer;
        }

        .jackpot-group__dropdown-item--link:hover {
            color: #E1C18F;
        }

        .jackpot-group__header-link:hover {
            color: #E1C18F;
        }
    }
}