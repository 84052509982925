/*
1. COUPONS
├── 1.1. COUPONS COMMON ELEMENTS
│   ├── 1.1.1 COUPONS MODALS
│   └── 1.1.2 COUPONS LIVE INDICATOR
├── 2. COUPONS
│   ├── 2.1. COUPONS MOBILE/TABLET
│   └── 2.2. COUPONS DESKTOP
└── 3. SPORT PAGE RIGHT PANEL DESKTOP
    └── 3.1 DESKTOP RIGHT PANEL TABS
*/

/*
|--------------------------------------------------------------------------
| == 1.1. COUPONS COMMON ELEMENTS
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 1.1.1 COUPONS MODALS
|--------------------------------------------------------------------------
*/

.coupons-clear-modal {
    @extend %G-coupons-clear-modal;
}

/* ---------------------- Success coupons modal ---------------------- */
.ll-modal--success-bet-modal {
    @extend %G-success-bet-modal;

    .success-bet-modal__heading {
        color: #E1C18F;
    }

    .success-bet-modal__block {
        border: 1px solid #E1C18F;
    }

    .success-bet-modal__value {
        color: #E1C18F;
        font-weight: 500;
    }

    coupons__match-grid--selected {
        font-weight: 500;
    }

    .coupons__box {
        border: 1px solid #321e36;
        font-size: 1.4rem;

        &:not(:last-of-type) {
            border-bottom: none;
        }
    }

    .coupons__match-time {
        color: #BEBEBE;
    }

    .coupons__box:nth-of-type(odd) {
        background: #4B4B4B;
    }

    .coupons__remove {
        background: none;
        border: .1rem solid #BEBEBE;
    }

    .coupons__remove-icon {
        color: #BEBEBE;
    }

    .coupons__match-type {
        color: #fff;
    }

    .coupons__event-value {
        color: #E1C18F;
    }

    &.ll-modal--mobile {
        @extend %G-success-bet-modal--mobile;
    }

    &.ll-modal--tablet {
        @extend %G-success-bet-modal--tablet;
    }

    &.ll-modal--desktop {
        @extend %G-success-bet-modal--desktop;
    }
}


/*
|--------------------------------------------------------------------------
| == 1.1.2 COUPONS LIVE INDICATOR
|--------------------------------------------------------------------------
*/
.coupons__live-indicator {
    @extend %G-coupons__live-indicator;

    color: #E1C18F;
    animation-name: live_coupon_blink;
}

@-moz-keyframes live_coupon_blink {
    0% {
        color: #E1C18F;
    }
    50% {
        color: #AF8245;
    }
    100% {
        color: #E1C18F;
    }
}

@-webkit-keyframes live_coupon_blink {
    0% {
        color: #E1C18F;
    }
    50% {
        color: #AF8245;
    }
    100% {
        color: #E1C18F;
    }
}

@keyframes live_coupon_blink {
    0% {
        color: #E1C18F;
    }
    50% {
        color: #AF8245;
    }
    100% {
        color: #E1C18F;
    }
}

/*
|--------------------------------------------------------------------------
| == 2. COUPONS
|--------------------------------------------------------------------------
*/

.coupons {
    @extend %G-coupons;

    .coupons__amount {
        color: #FFFFFF;

        .coupons__amount-wrapper {
            border-color: #BEBEBE;

            &.amount-wrapper--error {
                border-color: #D93535;
            }
        }

        .coupons__amount-input input,
        .dx-texteditor.dx-editor-filled.dx-state-disabled input {
            color: #FFFFFF;
            background: transparent;
        }

        .dx-texteditor.dx-editor-filled.dx-state-disabled {
            opacity: 1;
        }

        .coupons__amount-input--dummy {
            background-color: #2E2C2E;
        }
    }

    .coupons-list__event--odd .coupons__amount-label--multi {
        background-color: #4B4B4B;
    }

    .coupons-list__event--even .coupons__amount-label--multi {
        background: #5D5D5D;
    }

    .coupons__settings {
        .coupons__amount-limit {
            color: #BEBEBE;
        }

        .amount-limit--error {
            color: #D93535;
        }

        .coupons__bet-system-picker--nullable {
            border: 1px solid #D93535;
        }

        .coupons__amount-wrapper {
            border-color: #4B4B4B;
        }

        .coupons__amount-preset-btn {
            background: #AF8245;
            color: #000000;
        }

        .coupons__amount-preset-edit {
            border-color: #AF8245;
            color: #AF8245;

            &:has(.doruk-close) {
                background-color: transparent;
            }

            .doruk-close {
                color: #BEBEBE;
            }
        }

        .amount-preset-editor__item {
            border: 1px solid #4B4B4B;
        }

        .amount-preset-editor__btn {
            background-color: #AF8245;
            color: #000000;
        }

        .coupons__bet-system-picker-button-text {
            background: transparent;
            color: #FFFFFF !important;
        }

        .coupons__bet-system-picker {
            background: #4B4B4B;

            &:hover,
            &.ll-dropdown-wrapper--opened {
                background: #2A1130;
            }

            .ll-dropdown-menu__icon,
            .ll-dropdown-menu__icon--opened {
                color: #FFFFFF;
            }

            .ll-dropdown-menu-item:not(:last-child),
            .ll-dropdown-menu-item--active:not(:last-child) {
                .coupons__bet-system-picker-item {
                    border-bottom: 1px solid #4B4B4B;
                }
            }
        }

        .coupons__bet-system-picker-button {
            color: #fff;
        }

        .coupons__bet-system-picker-item {
            background-color: #353236;

            .dx-checkbox-container {
                color: #FFFFFF;
            }
        }

        .coupons__bet-system-picker-button-text,
        .ll-dropdown-menu-item--active .coupons__bet-system-picker-item,
        .coupons__bet-system-picker-item:hover {
            .dx-checkbox-container {
                color: #E1C18F;
            }
        }

        .ll-dropdown-menu-item--active .coupons__bet-system-picker-item {
            .dx-checkbox-icon {
                background: #E1C18F;
            }
        }

        .coupons__bet-system-picker-item:hover {
            .dx-checkbox-icon {
                border-color: #E1C18F;
            }
        }

        .coupons__accept-btn {
            background-color: #E1C18F;
            color: #000000;
        }

        .coupons__detete-btn {
            color: #E1C18F;
            border-color: #E1C18F;
        }

        .coupons__checkbox {
            .dx-checkbox-text {
                color: #FFFFFF;
            }
        }

        .coupons__total-row--total {
            color: #AF8245;
        }

        .coupons__amount-row--total {
            color: #fff;
        }
    }

    .coupons__custom-warning {
        color: #BEBEBE;
    }
}

.coupons-list {
    @extend %G-coupons-item;

    .coupons-list__event {
        border: 1px solid #4B4B4B;
    }

    .coupons-list__event--odd {
        background: #4B4B4B;
    }

    .coupons-list__event--even {
        background: #5D5D5D;
    }

    .coupons-list__event-background-icon {
        color: #392F3B;
    }

    .coupons-list__event-date,
    .event-info__match-name,
    .coupons-list__event-type {
        color: #BEBEBE;
    }

    .coupons-list__event-remove-btn {
        background: #392F3B;
        color: #BEBEBE;

        &:hover {
            color: #FFFFFF;
        }
    }

    .only-single-error__icon {
        color: #E1C18F;
    }

    .only-single-error__icon-container {
        background-color: #AF8245;

        .only-single-error__icon {
            color: #fff;
        }
    }

    .only-single-error {
        background: #AF824580; //for browsers without linear-gradient
        background: linear-gradient(#AF82454D, #AF82454D), linear-gradient(#2E2C2Ecc, #2E2C2Ecc);
        color: #fff;
    }

    .coupons-list__event-part--main {
        color: #FFFFFF;
    }

    .coupons__event-value {
        color: #E1C18F;
    }

    .event-info__custom-bet--with-dots {
        &:before {
            box-shadow: 0 0 0 2px #4B4B4B;
            background-color: #BEBEBE;
        }

        &:after {
            border-left: 1px solid #BEBEBE;
        }
    }
    .event-info__custom-bet-selection-delete {
        color: #BEBEBE;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.1. COUPONS MOBILE/TABLET
|--------------------------------------------------------------------------
*/

.coupons--mobile,
.coupons--tablet {
    @extend %G-coupons--mobile--tablet;

    background: #2E2C2E;

    .coupons__container {
        border-top: 0.1rem solid #363137;
        border-bottom: none;
        background: #211024;
        color: #FFFFFF;
    }

    .coupons__bet-value {
        background: #E1C18F;
        color: #000000;
    }

    .coupons__close-icon {
        color: #FFFFFF;
    }

    .coupons__view-tabs {
        background: #2A1130;
        color: #E1C18F;
    }

    .coupons__view-tab {
        &:not(:last-of-type) {
            &:after {
                background-color: #363137;
            }
        }

        &.active {
            background: #E1C18F;
            color: #000000;
        }

        &.disabled {
            opacity: .7;
        }
    }

    .coupons-list__event {
        margin-bottom: 0;
        border: none;
        border-bottom: 1px solid #4D4D4D;
    }

    .coupons__settings {
        background: #2E2C2E;
    }

    .coupons__amount-wrapper {
        border: 1px solid #4B4B4B;
    }

    .coupons__bet-system-dropdown-menu {
        border: 1px solid #392F3B;
    }
}

.coupons--mobile {
    bottom: $size-height--footer_mobile;
}

.coupons--tablet {
    bottom: $size-height--footer_tablet;

    .coupons__scrollbox.coupons__scrollbox--open {
        max-height: $size-max-height--coupons-list_tablet;
    }
}

/*
|--------------------------------------------------------------------------
| == 2.2. COUPONS DESKTOP
|--------------------------------------------------------------------------
*/

.coupons--desktop {
    @extend %G-coupons--desktop;

    .coupons__loader {
        background: rgba(0, 0, 0, 0.1);
    }

    .prematch__coupons--empty {
        background: #2A1130;
        color: #fff;
        border-top: 1px solid #392F3B;
    }

    .coupons__view-tabs {
        background: #2A1130;
        color: #BEBEBE;
        border-top: 1px solid #392F3B;
    }

    .coupons__view-tab {
        &:not(:last-of-type) {
            &:after {
                background-color: #36313790;
            }
        }

        &.active {
            color: #fff;
        }
    }

    .coupons__settings {
        background: #2E2C2E;
    }

    .coupons__amount-wrapper {
        border: 1px solid #4B4B4B;
    }
}

.body--with-cursor {
    .event-info__custom-bet-selection-delete:hover {
        color: #fff;
    }

    .coupons__view-tab:not(.disabled):not(.active):hover {
        color: #fff;
    }

    .coupons__accept-btn:hover {
        background: #AF8245;
        opacity: 1;
    }

    .coupons__detete-btn:hover {
        background: #E1C18F;
        color: #2E2C2E;
    }

    .coupons__amount-wrapper:hover {
        border-color: #bebebe;
    }

    .coupons__amount--multi .coupons__amount-wrapper:hover {
        border-color: #fff;
    }

    .coupons__amount-preset-btn:hover {
        background: #E1C18F;
    }

    .coupons__amount-preset-edit:hover {
        border: 1px solid #E1C18F;
        background-color: #2E2C2E;
        color: #E1C18F;

        &:has(.doruk-close) {
            border-color: #2E2C2E;
            background-color: #2E2C2E;
        }

        .doruk-close {
            color: #E1C18F;
        }
    }

    .amount-preset-editor__btn:hover {
        background-color: #E1C18F;
    }
}

/*
|--------------------------------------------------------------------------
| == 3. SPORT PAGE RIGHT PANEL DESKTOP
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.1 DESKTOP RIGHT PANEL TABS
|--------------------------------------------------------------------------
*/

.coupon-tabs {
    @extend %G-coupons-tabs;

    .coupon-tabs__header-tab {
        background: #392F3B;
        color: #fff;

        &.active {
            background: #2A1130;
        }
    }

    .coupon-tabs__header-icon-count {
        background-color: #E1C18F;
        color: #000;

        &.animated {
            background-color: #DBBD6C;
        }
    }
}

.page-wrapper--live {
    .coupon-tabs {
        @extend %G-coupons-tabs--live;
    }
}