/*
3.PAGES
└── 3.6. MY ACCOUNT PAGE
    ├── 3.6.1 MY INFORMATION
    ├── 3.6.2 PAYMENTS
    ├── 3.6.4 ACCOUNT HISTORY
    ├── 3.6.5 BET HISTORY
    ├── 3.6.6 CASINO REPORT
    ├── 3.6.7 MY LIMITS
    └── 3.6.8 GOOGLE AUTHORIZATION
*/

/*
|--------------------------------------------------------------------------
|  == 3.6. MY ACCOUNT PAGE
|--------------------------------------------------------------------------
*/

.my-account--desktop,
.my-account--tablet,
.my-account--mobile {
    background: #231D24;

    @extend %G-my-account;

    .bet-delete-icon {
        color: #ccc;
    }
}

.my-account--tablet,
.my-account--mobile {
    @extend %G-my-account--mobile;

    .my-account-section {
        border: none;
    }

    .my-account-section__title {
        background: #211024;
        color: #fff;
    }

    .my-account-section--secondary {
        .my-account-section__title {
            background: #2F2631;
        }
    }
}

.my-account__title--mobile {
    @extend %G-my-account__title--mobile;

    background: #291A2D;
    color: #E1C18F;

    .my-account__title-back-icon {
        color: #E1C18F;
    }
}

.my-account--tablet,
.my-account--desktop {
    @extend %G-my-account--tablet-desktop;
}

.my-account--tablet {
    @extend %G-my-account--tablet;

    .my-account__title {
        background: #353236;
        color: #E1C18F;
    }
}

.my-account--desktop {
    @extend %G-my-account--desktop;
    background: transparent;

    .account-menu_link-icon {
        fill: rgba(0, 0, 0, 0.65);
    }

    .my-account__title {
        background: #291A2D;
        color: #E1C18F;
    }
}

.my-account-drawer {
    @extend %G-my-account-drawer;

    .my-account-drawer__blocked-user {
        color: #E1C18F;
    }

    .my-account-drawer__menu-list {
        background: #424242;
    }

    .my-account-drawer__menu-link {
        color: #fff;

        &.active {
            color: #E1C18F;
        }
    }
}

.my-account-info-list {
    @extend %G-my-account-info-list;

    .my-account-info-list__item {
        color: #fff;
        background: #5D5D5D;

        &:nth-child(2n) {
            background: #353236;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #383838;
        }
    }

    .my-account-info-list__item-value--negative {
        color: #f16969;
    }
}

.body--with-cursor {
    .bet-delete-icon:hover {
        color: #fff;
    }

    .my-account-drawer__close-btn:hover {
        color: #fff;
    }

    .my-account-drawer__menu-link:hover {
        color: #E1C18F;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.1 MY ACCOUNT PAGE -> MY INFORMATION
|--------------------------------------------------------------------------
*/

.my-account--tablet,
.my-account--mobile {
    .my-info {
        @extend %G-my-info--mobile;

        .my-info__change-pass {
            color: #e1c18f;
        }
    }
}

.my-info__wrapper--tablet,
.my-info__wrapper--mobile {
    @extend %G-my-info__wrapper--mobile;
}

.my-info__wrapper--tablet {
    @extend %G-my-info__wrapper--tablet;
}

.my-info__wrapper--desktop {
    @extend %G-my-info__wrapper--desktop;

    color: #fff;
    
    .my-info__coll {
        background: #4B4B4B;
    }

    .my-info__sub-title {
        background: #3D3B3D;
    }

    .my-info__row {
        border-bottom: 1px solid #888;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .my-info__cell-link {
        background-color: transparent;
        color: #AF8245;
        text-shadow: 0 0 0 #AF8245;
    }
}

.body--with-cursor {
    .my-info__cell-link:hover {
        text-shadow: 0 0 7px #AF8245;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.2 PAYMENTS
|--------------------------------------------------------------------------
*/
.payments {
    @extend %G-payments;

    .payments__icon {
        color: #AF8245;
    }

    .payment__btn-link-text {
        color: #fff;
    }
    .payment__btn-link-name {
        color: #000;
    }
}

.payments__content--mobile,
.payments__content--desktop {
    @extend %G-payments-btn-link;

    .payment {
        box-shadow: 3px 2px 5px #00000038;
        background-color: #fff;
    }
}

.payments__content--mobile {
    .payment {
        border: 1px solid #00000038;
    }
}

.payments__content--desktop {
    .payments__no-data {
        background: #3D3B3D;
    }

    .payment {
        border: 1px solid #00000038;
    }
}

.payment-modal {
    @extend %G-payment-modal;
}

/*
|--------------------------------------------------------------------------
| == 3.6.4 ACCOUNT HISTORY
|--------------------------------------------------------------------------
*/

.my-account--mobile .account-history {
    .my-account-info-list {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        overflow: hidden;
    }
}

.account-report--mobile {
    @extend %G-account-report--mobile;

    background: #353E4C;
    color: #fff;

    .account-report-item {
        background-color: #211024;
    }

    .account-report-item:not(:last-of-type) {
        border-right: 1px solid #2A1130;
    }

    .account-report-item--active {
        color: #E1C18F;
        background-color: #2A1130;
    }

    .ll-burger-icon--opened {
        div {
            background-color: #DBBD6C;
        }
    }
}

.account-report--tablet,
.account-report--desktop {
    @extend %G-account-report--desktop;

    .account-report-item {
        background: #4B4B4B;
    }

    .my-account-info-item__content--overflow {
        background: #4B4B4B;

        &.expanded {
            box-shadow: 0 2px 8px 0px #0000002b;
        }
    }

    .my-account-info-item__title {
        background: #3D3B3D;
        color: #fff;
    }

    .my-account-info-list__item {
        border-bottom: 1px solid #888;
    }

    .my-account-info-list__item-value--negative {
        color: #f16969;
    }

    .my-account-info-item__expand-panel--icon {
        color: #fff;
    }
}

.account-report--tablet {
    .account-report-item {
        border: 1px solid #C5C5C5;
    }

    .my-account-info-item__title {
        background: #353e4c;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.5 BET HISTORY
|--------------------------------------------------------------------------
*/
.bet-history--mobile,
.bet-history--tablet {
    @extend %G-bet-history--mobile-tablet;

    .status-filter-switcher-switch-base {
        color: #E1C18F !important;
    }

    .bet-history__status-active-bets--disabled,
    .bet-history__status-filter--disabled {
        color: #E1C18F85;

        .ll-dropdown-menu__icon {
            color: #E1C18F85;
        }
    }

    .status-filter-switcher-track {
        background-color: #4D4D4D !important;
        opacity: 1!important;
    }

    .bet-history__status-filter {
        .ll-dropdown-menu__icon {
            color: #DBBD6C;
        }

        .bet-history__status-filter-dropdown {
            background: #4D4D4D;
            border: 1px solid #291A2D;
            color: #fff;

            .ll-dropdown-menu-item,
            .ll-dropdown-menu-item--disabled,
            .ll-dropdown-menu-item--active {
                &:not(:last-of-type) {
                    border-bottom: 1px solid #291A2D;
                }
            }

            .ll-dropdown-menu-item--disabled {
                color: #B0B0B0;
            }

            .ll-dropdown-menu-item--active {
                background: #291A2D;
            }
        }
    }

    .ll-date-range-picker__request-btn {
        background: #DBBD6C!important;
    }
}

.bet-history--tablet {
    @extend %G-bet-history--tablet;
}

.bet-history--desktop {
    @extend %G-bet-history--desktop;

    .bet-history__cashout-cell-btn {
        background: #DBBD6C;
        color: #000;
    }

    /* ---------------------- Bet details in table ---------------------- */

    .bet-history-detail__info {
        background-color: #3d3b3d;
        color: #bebebe;
    }

    .bet-history-detail__info-row {
        border: 1px solid #515159;
        border-top: none;
    }

    .bet-history-detail__info-td {
        &:not(:last-child)::after {
            background-color: #e1c18f;
        }
    }
}

.body--with-cursor {
    .bet-history__cashout-cell-btn:hover {
        box-shadow: inset 0 0 1px 1px #DBBD6C;
        background: transparent;
        color: #DBBD6C;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.6 CASINO REPORT
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.6.7 MY LIMITS
|--------------------------------------------------------------------------
*/

.my-limits {
    @extend %G-my-limits;

    .my-limits__provider-limit-info-icon {
        color: #af8245;
    }
}

.my-limits--mobile,
.my-limits--tablet {
    @extend %G-my-limits--mobile-table;

    .my-limits__content-box--rows .my-limits__content-box-title {
        background-color: #211024;
        color: #fff;
    }

    .my-limits__content-box--info .my-limits__content-box-title {
        color: #af8245;
    }

    .my-limits__content-box-wrp {
        color: #fff;
    }

    .my-limits__content-row {
        &:nth-child(2n + 1) {
            background-color: #5d5d5d;
        }

        &:nth-child(2n) {
            background-color: #353236;
        }
    }
}

.my-limits--desktop {
    @extend %G-my-limits--desktop;

    .my-limits__content-box {
        box-shadow: 0 0 5px 0 #00000020;
        color: #fff;
    }

    .my-limits__content-box-title {
        background: #3d3b3d;
    }

    .my-limits__content-box-wrp {
        background-color: #4b4b4b;
    }

    .my-limits__content-row {
        &:not(:last-child) {
            border-bottom: 1px solid #888;
        }

        & > * {
            &:first-child {
                border-right: 1px solid #888;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.8 MY ACCOUNT PAGE -> GOOGLE AUTHORIZATION
|--------------------------------------------------------------------------
*/
.google-auth {
    @extend %G-google-auth;

    .google-auth__content {
        border: 1px solid #888;
        background: #4B4B4B;
        color: #fff;
    }

    .google-auth__img-qr {
        @include size(
            $size-width--cabinet-google-auth-img + px,
            $size-height--cabinet-google-auth-img + px
        );
    }

    .google-auth__qr-img-container {
        border-bottom: 1px solid #888;
    }

    .google-auth__install-app {
        border-bottom: 1px solid #888;
    }

    .google-auth__form-password,
    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password;
    }

    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password--mobile;
    }

    .google-auth__form-qr-code,
    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code;
    }

    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code--mobile;
    }

    .google-auth__submit-btn {
        background: #E1C18F;
    }
}

.my-account--mobile .google-auth {
    @extend %G-google-auth--mobile;
}

/*
|--------------------------------------------------------------------------
| == 3.6.9 MY ACCOUNT PAGE -> MESSAGES PAGE
|--------------------------------------------------------------------------
*/

.messages {
    @extend %G-messages;

    .message-item {
        background-color: #4b4b4b;
        color: #fff;
    }

    .message-date {
        color: rgb(151, 151, 151);
    }

    .message-unread {
        background-color: #3d3b3d;
    }
}

.messages--mobile,
.messages--tablet {
    @extend %G-messages--mobile;
}

.messages--desktop {
    @extend %G-messages--desktop;
}