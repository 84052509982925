@import '../variables.scss';

/*
3.PAGES
└── 3.11. ERROR PAGE
*/

/*
|--------------------------------------------------------------------------
| == 3.11. ERROR PAGE
|--------------------------------------------------------------------------
*/
.error {
    @extend %G-error;
    
    .error__title,
    .error__text {
        text-align: center;
    }

    .error__title {
        font-size: 2.4rem;
    }

    .error__text {
        font-size: 1.8rem;
    }

    .ll-btn {
        line-height: unset;
        height: unset;
    }

    .ll-btn__link {
        outline: none;
        font-weight: 500;
        user-select: none;
        font-size: 1rem;
        text-transform: uppercase;
        background: #E1C18F;
        color: #000;
    }

    .ll-btn__link:hover {
        background-color: #AF8245;
    }
}

.page-wrapper--tablet .error {
    @extend %G-error--tablet;
}

.page-wrapper--mobile .error {
    @extend %G-error--mobile;
}

.ll-error-visualizer {
    @extend %G-error-visualizer;

    .ll-error-visualizer__header {
        background:  #E1C18F!important;
        color: #000;
    }

    border-color: #B8B8B8;
    box-shadow: none;
    background: #291A2D;
    color: #fff;
}

.global-error {
    @extend %G-global-error;
}

.ll-page--error {
    @extend %G-page--error;
}